.jobDescriptionComponent {
  .row {
    .jobTitle {
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }
    .title {
      text-decoration: underline;
      text-underline-offset: 10px;
      text-decoration-thickness: 1px;
      padding-bottom: 10px;
    }
    .responsibilities {
      list-style: none;
      li {
        &::before {
          font-family: FontAwesome;
          content: "\f00c";
          margin-right: 20px;
        }
      }
    }
  }
}
