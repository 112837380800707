html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}


