.contentServices {
  .mainImg {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }
  .container-fluid {
    display: flex;
    justify-content: space-evenly;
    .row {
      width: 100%;
      .boxCardWrap {
        flex-basis: 33%;
        display: flex;
        justify-content: center;
        .boxCard {
          flex-basis: 100%;
          border: 1px solid #ccc;
          max-width: 400px;
          padding: 20px;
          box-shadow: 0 0 10px #ccc;
          border: 1px solid #ccc;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          .servImg {
            width: auto;
            height: 200px;
            margin: 0 auto;
          }
          ul {
            list-style: none;
            list-style-position: outside;
            li {
              font-weight: 400;
              &:before {
                font-family: "FontAwesome";
                content: "\f105";
                margin-right: 15px;
                color: $baseColor;
              }
            }
          }
        }
      }
      .hr {
        width: 100%;
        height: 1px;
        background-color: #eee;
      }
      p {
        font-size: 15px;
        font-weight: 400;
        margin: 10px 30px;
        text-align: justify;
      }
    }
  }
  .spacer {
    margin: 30px 0;
    width: 100%;
    height: 100px;
    background-color: $baseColor;
  }
}
