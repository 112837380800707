.contactComponent {
  input:not([type]),
  input[type='password'],
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  input[type='text'],
  input[type='email'],
  textarea {
    border: 1px solid #c9c9c9;
    border-radius: 0;
    background: #fff;
    padding: 0.618em 1em;
    font-family: Poppins, sans-serif;
    box-shadow: none;
    min-height: 40px;
    width: 100%;
    font-size: 12px;
    transition: all 0.3s ease-in;
    &:focus {
      border: 1px solid #217dbb;
      outline: none;
      box-shadow: none;
    }
  }

  button {
    background: #4aa3df;
    width: auto;
    border: 2px solid #4aa3df;
    color: #fff;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 30px;
    transition: all 0.25s ease-in-out;
    &:hover {
      color: #4aa3df;
      background: #fff;
    }
  }
}
