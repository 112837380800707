footer {
  margin-top: 40px;
  .container {
    .row {
      .col-8 {
        img {
          width: 70%;
          height: auto;
        }
      }
      .col-4 {
        .address {
          font-size: 16px;
          font-family: "Alegreya Sans SC";
          text-transform: lowercase;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
    .copyright {
      border-top: 1px solid #eee;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      a {
        .socIcons {
          font-size: 28px;
          margin: 5px 10px;
        }
      }
      .dev {
        float: right;
        font-family: $titleFont;
        font-size: 13px;
        text-transform: uppercase;
      }
    }
  }
}
