header {
  position: fixed;
  width: 100%;
  box-shadow: 0px 5px 10px #ccc;
  height: 70px;
  z-index: 100000;
  display: flex !important;
  align-items: center !important;
  .navbar {
    width: 100%;
    padding: 0;
    display: flex !important;
    align-items: center !important;
    background-color: #fff !important;
    height: 100%;
    .navbar-brand {
      display: flex;
      align-items: center;
      justify-items: flex-start;
      .logo {
        max-height: 45px;
      }
    }
    .navbar-collapse {
      height: 100%;
      padding: 0 30px;
      .navbar-nav {
        height: 100%;
        display: flex;
        align-items: center;
        .nav-link {
          height: 100%;
          font-weight: 400;
          display: flex;
          align-items: center;
          &:hover {
            background-color: #217dbb !important;
            color: #fff !important;
          }
          &.active {
            background-color: #217dbb !important;
            color: #fff !important;
          }
        }
      }
    }
  }
}
