html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $baseFont;
  color: $baseFontColor;
  font-weight: 300;
  font-size: 13px;
}
.title2 {
  color: $baseColor;
  font-size: 17.75px;
  line-height: 2.21813em;
  margin-bottom: 0.36969em;
}
.title3 {
  background-color: $baseColor;
  color: #fff;
  font-size: 17.75px;
  line-height: 2.21813em;
  margin-bottom: 0.36969em;
  text-align: center;
  margin-top: 20px;
}

ui-error {
  font-size: 12px;
  color: red;
}
