.careersComponent {
  .jobContainer {
    margin-top: 70px;
    margin-bottom: 70px;
    .row {
      .jobTitle {
        padding: 8px 30px;
        border: 1px solid #ccc;
        box-shadow: 0 5px 10px #ccc;
        margin-bottom: 30px;
        border-radius: 12px;
        transition: all 0.3s ease-in-out;
        color: #78909c;
        cursor: pointer;
        &:hover {
          background: rgb(255, 255, 255);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(250, 250, 250, 1) 20%,
            rgba(230, 230, 230, 1) 100%
          );
          color: #343434;
        }
        &::after {
          font-family: FontAwesome;
          content: "\f105";
          float: right;
          color: #cfd8dc;
        }
      }
    }
  }
}
