.contentHome {
  .mainImg {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }
  .container {
    .row {
      .col-12 {
        .title {
          width: 100%;
          font-family: "Alegreya Sans SC", sans-serif;
          font-size: 19px;
          background-color: $baseColor;
          color: #fff;
          padding: 5px 30px;
        }
      }
      p {
        font-size: 15px;
        font-weight: 400;
        margin: 10px 30px;
        text-align: justify;
      }
    }
  }
  .spacer {
    margin: 30px 0;
    width: 100%;
    height: 100px;
    background-color: $baseColor;
  }
}
